<script setup lang="ts">
import type { LandingPageBlok } from 'types/storyblok/pages/landing-page'
import StoryblokProductListBlock from '../../../storyblok/ProductListBlock.vue'
import type { Product } from '~/types/models/product'
import { isProduct } from '~/types/models/product'
import { isProductListBlock, normalizeProductListBlock } from '~/types/storyblok/product-list-block'
import assignCategories from '~/utils/products/assignCategories'

interface Props {
  blok: LandingPageBlok
}
const props = defineProps<Props>()

const storyblokStore = useStoryblokStore()
const gtm = useGTM()

const widgetId = props.blok.widget
  ? storyblokStore.state.widgets?.get(props.blok.widget)
  : undefined

// track the widgetId for landing page (used on homepage and campaign pages)
useProductStore().currentWidgetId = widgetId

const productBlocks = computed(() =>
  props.blok.body
    .filter(isProductListBlock)
    .map(b => ({ _uid: b._uid, ...normalizeProductListBlock(b, storyblokStore.state.categories) })),
)
const pageProductVariables = computed(() =>
  productBlocks.value.map((b) => {
    if (b.categoryTag)
      return b.categoryTag

    if (b.category)
      return `category-${b.category}`

    return 'default'
  }),
)

const { data, pending } = useLazyAsyncData(
  `products-${props.blok._uid}`,
  async () => {
    if (!productBlocks.value.length || !widgetId)
      return Promise.resolve(undefined)

    const variables = pageProductVariables.value
    const { $products } = useNuxtApp()

    return $products.fetchProducts({
      widgetId,
      variables: assignCategories(variables),
    })
  },
  {
    deep: false,
  },
)

const productsDistributionsMap = computed(() => {
  const map = new Map<string, Product[]>()

  productBlocks.value.forEach((block, index) => {
    const products = data.value?.items[index]?.filter(isProduct) || []
    map.set(block._uid, products)
  })

  return map
})

function trackPageBlockClick(url: string, clickType: string, blockPosition: number) {
  gtm.pushPageBlockClick({ clickURL: url, clickType, clickBlockPosition: blockPosition })
}

onMounted(() => {
  const nuxtApp = useNuxtApp()

  if (data.value?.id && nuxtApp.isHydrating)
    useRootStore().addEarlyBirdRecord(data.value.id)
})
</script>

<template>
  <main v-editable="blok">
    <div
      v-for="(component, index) in blok.body"
      :key="component._uid"
      data-test="landing-page-block"
    >
      <StoryblokProductListBlock
        v-if="isProductListBlock(component) && widgetId"
        :blok="component"
        :products="!data && pending ? [] : productsDistributionsMap.get(component._uid)"
        :data-position="index"
        @track-block-click="
          (url: string, clickType: string) => trackPageBlockClick(url, clickType, index)
        "
      />
      <component
        :is="component.component"
        v-else
        :key="component._uid"
        :blok="component"
        :data-position="index"
        @track-block-click="
          (url: string, clickType: string) => trackPageBlockClick(url, clickType, index)
        "
      />
    </div>
  </main>
</template>
